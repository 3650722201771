import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroVideo from "../components/hero-video";
import { LandingPageWrapper } from "../components/PageContainer";

const IndexPage = () => {
    return (
        <Layout>
            <SEO title="Home" />
            <LandingPageWrapper>
                <HeroVideo />
            </LandingPageWrapper>
        </Layout>
    );
};

export default IndexPage;
