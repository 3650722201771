import styled from "styled-components";
import Img from "gatsby-image";
import { breakpoints } from "../../styles/variables";
import { colors } from "../../styles/variables";
import { FaPlay } from "react-icons/fa";

export const Wrapper = styled.div`
    position: relative;
    height: auto;
    @media (min-width: ${breakpoints.sm}) {
        padding: 0 0 2rem 0;
    }
`;

export const SliderWrapper = styled.div`
    position: relative;
`;

export const StyledImg = styled(Img)``;

export const StyledButton = styled.button`
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    outline: none;
    border: solid 2px #fff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background-color: transparent;
    transition: border-color 0.3s ease, color 0.3s ease;

    &:hover {
        border-color: ${colors.primary};
        svg {
            color: ${colors.primary};
        }
    }
    &:focus {
        outline: none;
    }
    font-size: 3rem;
    @media (min-width: ${breakpoints.lg}) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const Description = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 auto;
    padding: 3rem 1rem 3rem 1rem;
    max-width: 1000px;
    text-align: center;
    letter-spacing: 1px;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
    position: relative;
    bottom: unset;
    left: 0;
    transform: translate(0, 0);
    @media (min-width: ${breakpoints.lg}) {
        font-size: 2rem;
        margin-top: -50px;
        z-index: 1;
        padding: 3rem 5rem 5rem 5rem;
    }
`;
export const Date = styled.p`
    margin: 0.25rem auto 0;
    font-size: 1.125rem;
    font-weight: 500;
    @media (min-width: ${breakpoints.lg}) {
        font-size: 1.5rem;
    }
`;
export const Details = styled.p`
    margin-top: 1.5rem;
    font-size: 1.2rem;
    font-weight: 300;
    white-space: break-spaces;
    @media (min-width: ${breakpoints.lg}) {
        text-align: center;
        margin-top: 3rem;
    }
`;

export const StyledIcon = styled(FaPlay)`
    font-size: 1.7rem;
    color: #fff;
`;
