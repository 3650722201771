import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";

import {
    Wrapper,
    SliderWrapper,
    StyledImg,
    Description,
    Date,
    Details,
} from "./styles";

const slideSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    fade: true,
    speed: 1500,
    cssEase: "linear",
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: false,
};

const HeroVideo = () => {
    const image = useStaticQuery(graphql`
        fragment ImageFragment on File {
            childImageSharp {
                fluid(
                    maxWidth: 3000
                    maxHeight: 1300
                    grayscale: false
                    quality: 100
                ) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        query {
            thumbnail_1: file(
                relativePath: { eq: "koffia-closing-2024-1.jpg" }
            ) {
                ...ImageFragment
            }
            thumbnail_2: file(
                relativePath: { eq: "koffia-closing-2024-2.jpg" }
            ) {
                ...ImageFragment
            }
            thumbnail_3: file(
                relativePath: { eq: "koffia-closing-2024-3.jpg" }
            ) {
                ...ImageFragment
            }
            thumbnail_4: file(
                relativePath: { eq: "koffia-closing-2024-4.jpg" }
            ) {
                ...ImageFragment
            }
            thumbnail_5: file(
                relativePath: { eq: "koffia-closing-2024-5.jpg" }
            ) {
                ...ImageFragment
            }
            thumbnail_6: file(
                relativePath: { eq: "koffia-closing-2024-7.jpg" }
            ) {
                ...ImageFragment
            }
        }
    `);

    return (
        <Wrapper>
            <SliderWrapper>
                <Slider {...slideSettings}>
                    <StyledImg
                        fluid={image.thumbnail_1.childImageSharp.fluid}
                    />
                    <StyledImg
                        fluid={image.thumbnail_2.childImageSharp.fluid}
                    />
                    <StyledImg
                        fluid={image.thumbnail_3.childImageSharp.fluid}
                    />
                    <StyledImg
                        fluid={image.thumbnail_4.childImageSharp.fluid}
                    />
                    <StyledImg
                        fluid={image.thumbnail_5.childImageSharp.fluid}
                    />
                    <StyledImg
                        fluid={image.thumbnail_6.childImageSharp.fluid}
                    />
                </Slider>
            </SliderWrapper>
            <Description>
                Thank You for Joining Us at KOFFIA 2024!
                <Details>
                    As we bring this year's Korean Film Festival in Australia
                    (KOFFIA) to a close, we want to express our heartfelt thanks
                    to all of you—our wonderful audience—for making it such a
                    memorable event. From Sydney to the cities and towns across
                    our first-ever regional touring program, your enthusiasm and
                    support have been incredible.
                </Details>
                <Details>
                    We are especially proud to have introduced KOFFIA to new
                    communities this year, and your positive response ensures
                    that we'll be working hard to bring the festival to even
                    more cities across Australia next year. Stay tuned for
                    exciting updates as we prepare to celebrate the best of
                    Korean cinema with you again in 2025!
                </Details>
                <Details>
                    Until then, thank you for being a part of KOFFIA 2024, and
                    we look forward to seeing you again soon.
                </Details>
                <Details>{"Warm regards,\n The KOFFIA Team"}</Details>
            </Description>
        </Wrapper>
    );
};

export default HeroVideo;
